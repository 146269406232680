import { useQuery } from "@tanstack/react-query"
import { Bundle, ChargeItemDefinition, getResources, Parameters } from "fhir"

import { useClient } from "api"
import { BILLING_TYPES_CODES } from "data"
import { getIndexedCID } from "utils"

import { GroupedChargeItemDefinitionsCodes } from "../../types"
import { medsQueryKeys } from "../meds_query_keys"
import { getIndexedCIDBySku, getParamsSkuCodesByFee } from "../utils"

const useGetMedicationsProductPrices = ({
  organizationId,
  codes,
  useSpecificCodes = false,
}: {
  organizationId: string
  codes?: GroupedChargeItemDefinitionsCodes
  useSpecificCodes?: boolean
}) => {
  const getChargeItemDefinitions = useMedPricesQueryFunction()
  const queryKey = medsQueryKeys.medicationProductFee(organizationId, codes, useSpecificCodes)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => getChargeItemDefinitions(organizationId, codes, useSpecificCodes),
    enabled: !!codes?.billToPracticeOrInsuranceCIDs?.length || !!codes?.billToPatientCIDs?.length,
    meta: { context: { queryKey, codes } },
  })

  return {
    isLoading,
    chargeItemDefinitions: data,
  }
}

const useMedPricesQueryFunction = () => {
  const { operationRequest } = useClient()

  return async (organizationId: string, codes?: GroupedChargeItemDefinitionsCodes, useSpecificCodes?: boolean) => {
    const billToPracticeOrInsuranceCIDsPart = codes?.billToPracticeOrInsuranceCIDs?.length
      ? getParamsSkuCodesByFee(codes?.billToPracticeOrInsuranceCIDs)
      : []
    const billToPatientCIDsPart = codes?.billToPatientCIDs?.length
      ? [
          ...(getParamsSkuCodesByFee(codes?.billToPatientCIDs) ?? []),
          {
            name: "order-type",
            value: {
              string: "bill-patient",
            },
          },
        ]
      : []

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "order-info",
          part: billToPracticeOrInsuranceCIDsPart,
        },
        {
          name: "order-info",
          part: billToPatientCIDsPart,
        },
        {
          name: "include-fee",
          value: {
            boolean: true,
          },
        },
      ],
    }

    const bundle: Parameters = await operationRequest<Parameters>({
      endpoint: `Organization/${organizationId}`,
      method: "POST",
      operation: "medication-product-fee",
      parameters,
    })

    let billToPracticeOrInsuranceCIDs: ChargeItemDefinition[] = []

    const billToPracticeOrInsuranceCIDsBundle = bundle?.parameter?.find(
      (p) => p.name === BILLING_TYPES_CODES.BILL_PRACTICE,
    )?.resource as Bundle
    if (billToPracticeOrInsuranceCIDsBundle) {
      billToPracticeOrInsuranceCIDs = getResources<ChargeItemDefinition>(
        billToPracticeOrInsuranceCIDsBundle,
        "ChargeItemDefinition",
      )
    }

    let billToPatientCIDs: ChargeItemDefinition[] = []

    const billToPatientCIDsBundle = bundle?.parameter?.find((p) => p.name === BILLING_TYPES_CODES.BILL_PATIENT)
      ?.resource as Bundle
    if (billToPatientCIDsBundle) {
      billToPatientCIDs = getResources<ChargeItemDefinition>(billToPatientCIDsBundle, "ChargeItemDefinition")
    }

    return {
      billToPracticeOrInsuranceCIDs: useSpecificCodes
        ? getIndexedCID(billToPracticeOrInsuranceCIDs, true)
        : getIndexedCIDBySku(billToPracticeOrInsuranceCIDs),
      billToPatientCIDs: useSpecificCodes
        ? getIndexedCID(billToPatientCIDs, true)
        : getIndexedCIDBySku(billToPatientCIDs),
    }
  }
}

export { useGetMedicationsProductPrices, useMedPricesQueryFunction }
